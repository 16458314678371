{
  "name": "timeghost",
  "version": "1.0.0",
  "private": true,
  "scripts": {
    "ng": "ng",
    "git": "node ./git-config.js",
    "prod": "cross-env NODE_ENV=production npm run build -- --configuration production",
    "prod:hm": "cross-env NODE_ENV=production npm run build:hm -- --configuration production",
    "build": "npm run env -s && ng build",
    "build:hm": "npm run env -s && node --max_old_space_size=8192 ./node_modules/@angular/cli/bin/ng build",
    "analyze:es5": "webpack-bundle-analyzer dist/stats-es2015.json",
    "start": "npm run env -s && node --max_old_space_size=16384 ./node_modules/@angular/cli/bin/ng serve --hmr",
    "dev": "cross-env TAILWIND_MODE=watch npm run start -- --ssl --ssl-key localhost.key --ssl-cert localhost.crt",
    "dev:local": "cross-env AZ_FUNC_ENDPOINT=http://localhost:7071/api npm run dev --",
    "light:start": "cross-env NODE_OPTIONS=--max-old-space-size=400 npm start",
    "serve:sw": "npm run build -s && npx http-server ./dist -p 4200",
    "lint": "ng lint && stylelint \"src/**/*.scss\" --syntax scss && htmlhint \"src\" --config .htmlhintrc",
    "test": "npm run env -s && ng test",
    "test:ci": "npm run env -s && npm run lint -s && ng test --code-coverage --watch=false",
    "e2e": "npm run env -s && ng e2e",
    "translations:extract": "ngx-translate-extract --input ./src --output ./src/translations/template.json --format=json --clean -sort --marker extract",
    "env": "ngx-scripts env npm_package_version AZ_FUNC_ENDPOINT NODE_ENV && npm run git && cross-env NODE_OPTIONS=--max_old_space_size=8192",
    "prettier": "prettier --write \"./{src,e2e}/**/*.{ts,js,scss}\"",
    "prettier:check": "prettier --list-different \"./{src,e2e}/**/*.{ts,js,scss}\"",
    "postinstall": "npm run git",
    "generate": "ng generate",
    "refreshVSToken": "vsts-npm-auth -config .npmrc",
    "docker:build-agent": "docker run --env-file=./.env-docker --env-file=./.vscode/.docker -it mcr.microsoft.com/azure-pipelines/vsts-agent",
    "build:prod": "cross-env NODE_ENV=production ng build --configuration production"
  },
  "dependencies": {
    "@angular/animations": "^17.3.10",
    "@angular/cdk": "^17.3.10",
    "@angular/cdk-experimental": "^17.3.10",
    "@angular/common": "^17.3.10",
    "@angular/compiler": "^17.3.10",
    "@angular/core": "17.3.10",
    "@angular/forms": "^17.3.10",
    "@angular/http": "^7.2.15",
    "@angular/material": "^16.2.0",
    "@angular/platform-browser": "^17.3.10",
    "@angular/platform-browser-dynamic": "^17.3.10",
    "@angular/router": "^17.3.10",
    "@angular/service-worker": "^17.3.10",
    "@angularclass/hmr": "^3.0.0",
    "@azure/msal-angular": "^3.0.18",
    "@azure/msal-browser": "^3.15.0",
    "@datorama/akita": "^8.0.1",
    "@kurkle/color": "^0.1.9",
    "@microsoft/signalr": "^3.1.3",
    "@microsoft/teams-js": "^2.10.0",
    "@ncstate/sat-popover": "^8.0.1",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@paralleldrive/cuid2": "^2.2.2",
    "@rx-angular/cdk": "^17.1.0",
    "@rx-angular/template": "^17.3.1",
    "@saschwarz/ngx-paddle-wrapper": "^1.1.1",
    "@sentry/angular": "^8.7.0",
    "@sentry/angular-ivy": "^7.53.0",
    "@sentry/browser": "^7.14.0",
    "@sentry/tracing": "^7.53.0",
    "@tailwindcss/forms": "^0.3.3",
    "@tailwindcss/typography": "^0.5.2",
    "@uniprank/ngx-scrollspy": "https://github.com/Venipa/ngx-scrollspy-1#release",
    "angular-calendar": "^0.29.0",
    "angular-draggable-droppable": "^6.1.0",
    "angular-notifier": "^11.0.0",
    "angular-svg-icon": "^16.1.0",
    "apexcharts": "^3.26.1",
    "calendar-utils": "^0.8.2",
    "colorthief": "^2.3.2",
    "date-fns": "^2.28.0",
    "date-fns-tz": "^1.1.4",
    "file-saver": "^2.0.5",
    "flexsearch": "^0.7.21",
    "fuse.js": "^6.5.3",
    "immer": "6.0.6",
    "install": "^0.13.0",
    "lodash": "^4.17.21",
    "lodash-es": "^4.17.21",
    "luxon": "^3.4.4",
    "marked": "^9.1.6",
    "material-design-icons-iconfont": "^6.1.0",
    "mime": "^2.4.4",
    "ng-apexcharts": "^1.5.9",
    "ngx-color-picker": "^16.0.0",
    "ngx-currency": "^4.0.0",
    "ngx-date-fns": "^10.0.1",
    "ngx-device-detector": "^1.3.5",
    "ngx-flexible-layout": "https://github.com/Venipa/ngx-flex-layout#build/dev",
    "ngx-markdown": "^17.2.1",
    "ngx-mask": "^17.0.8",
    "ngx-mat-select-search": "^7.0.6",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-scrollbar": "^4.1.1",
    "ngx-skeleton-loader": "^8.1.0",
    "ngx-sonner": "^1.0.0",
    "ngx-sub-form": "^8.0.0",
    "ngx-tippy-wrapper": "^5.0.1",
    "prismjs": "^1.29.0",
    "roboto-fontface": "^0.10.0",
    "rxjs": "^7.8.1",
    "rxjs-take-while-inclusive": "^2.1.0",
    "thenby": "^1.3.4",
    "timeghost-api": "^0.0.26273",
    "timezone-support": "^2.0.2",
    "tippy.js": "^6.3.7",
    "zod": "^3.22.4",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "^17.0.2",
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.10",
    "@angular/language-service": "17.3.10",
    "@biesbjerg/ngx-translate-extract": "^2.3.4",
    "@datorama/akita-ngdevtools": "^4.0.0",
    "@fullhuman/postcss-purgecss": "^4.0.3",
    "@microsoft/microsoft-graph-types": "1.8.0",
    "@ngx-rocket/scripts": "^3.0.1",
    "@phenomnomnominal/angular-lazy-routes-fix": "^0.1.5",
    "@prettier/plugin-xml": "^0.10.0",
    "@sentry/webpack-plugin": "^1.19.0",
    "@tailwindcss/aspect-ratio": "^0.4.0",
    "@tailwindcss/line-clamp": "^0.4.0",
    "@types/events": "^3.0.0",
    "@types/file-saver": "^2.0.5",
    "@types/flexsearch": "^0.7.2",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "^2.0.6",
    "@types/lodash": "^4.14.182",
    "@types/lodash-es": "^4.17.6",
    "@types/luxon": "^1.12.0",
    "@types/mime": "^2.0.1",
    "@types/node": "^12.0.0",
    "autoprefixer": "^10.2.6",
    "codelyzer": "^6.0.0",
    "core-js": "^2.6.4",
    "cross-env": "^5.2.0",
    "fast-glob": "^3.2.6",
    "fiber": "^1.0.4",
    "git-describe": "^4.0.4",
    "hads": "^1.7.2",
    "htmlhint": "^0.11.0",
    "https-proxy-agent": "^2.2.1",
    "husky": "^4.3.8",
    "jasmine-core": "~3.6.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "^6.3.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-cli": "~2.0.0",
    "karma-coverage-istanbul-reporter": "^2.0.5",
    "karma-jasmine": "~4.0.0",
    "karma-junit-reporter": "^1.2.0",
    "ngx-infinite-scroll": "^17.0.1",
    "ngx-tailwind": "^4.0.0",
    "postcss": "^8.3.5",
    "postcss-import": "^14.0.2",
    "postcss-loader": "^6.2.0",
    "postcss-scss": "^4.0.1",
    "prettier": "^2.7.1",
    "prettier-plugin-tailwindcss": "^0.1.13",
    "pretty-quick": "^3.1.0",
    "protractor": "^7.0.0",
    "purgecss-webpack-plugin": "^4.0.3",
    "sass": "^1.43.5",
    "sass-loader": "^12.3.0",
    "stylelint": "~10.0.1",
    "stylelint-config-prettier": "^5.1.0",
    "stylelint-config-recommended-scss": "~3.3.0",
    "stylelint-config-standard": "~18.3.0",
    "stylelint-scss": "^3.6.1",
    "tailwindcss": "^3.4.1",
    "ts-node": "~8.1.0",
    "tslint": "~6.1.3",
    "tslint-config-prettier": "^1.18.0",
    "typescript": "^5.2.2",
    "webpack-bundle-analyzer": "^3.6.0"
  },
  "husky": {
    "hooks": {
      "pre-commit": "pretty-quick --staged",
      "pre-push": "npm run git"
    }
  },
  "scarfSettings": {
    "enabled": false
  },
  "prettier": {
    "singleQuote": true,
    "overrides": [
      {
        "files": "*.scss",
        "options": {
          "singleQuote": false
        }
      }
    ]
  },
  "packageManager": "yarn@1.22.19+sha512.ff4579ab459bb25aa7c0ff75b62acebe576f6084b36aa842971cf250a5d8c6cd3bc9420b22ce63c7f93a0857bc6ef29291db39c3e7a23aab5adfd5a4dd6c5d71"
}
